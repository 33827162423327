.candidates-grid {
  width: 100%;
  display: grid;
  grid-gap: 1rem;

  grid-template-columns: repeat(auto-fit, minmax(19rem, 0.2fr));
  @media only screen and (max-width: $bp-1400) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.25fr));
  }
  @media only screen and (max-width: $bp-1200) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-800) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-600) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
  @media only screen and (max-width: $bp-400) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
}
