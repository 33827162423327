.footer-logo {
  width: 20rem;
  @media only screen and (max-width: $bp-600) {
    width: 10rem;
  }
}
.footer {
  padding: 2rem;
  background-color: #ffffff !important;
  text-align: center;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  z-index: 0;
}
