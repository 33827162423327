.intro {
  padding: 2rem 3rem;
  width: 100%;
  min-height: 25rem;
  background-color: #f3f3f3;
  opacity: 0.97;
  border-radius: 5px;
  transition: all 0.4s ease-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &-welcome {
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
      font-weight: 500;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-profile {
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-testcases {
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-playground {
    padding: initial !important;
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-reports {
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-dashboard {
    border-bottom: 1px solid rgb(211, 211, 211);
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
  &-scenario {
    &-title {
      font-size: 3rem;
    }
    &-body {
      font-size: 1.6rem;
    }
  }
}
