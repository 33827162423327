// <div className="stats">
// <div className="stats-top">
//   <div className="stats-top">
//     <div className="stats-top-left">
//       <div className="stats-top-left-title">Title</div>
//       <div className="stats-top-left-body">body</div>
//     </div>
//     <div className="stats-top-right">
//       <div className="stats-top-right-title">Title</div>
//       <div className="stats-top-right-body">body</div>
//     </div>
//   </div>
// </div>
// <div className="stats-bottom">
//   <div className="stats-bottom-title">Title</div>
//   <div className="stats-bottom-body">body</div>
// </div>
// </div>

.stats {
  width: 100%;
  background-color: white;
  //   box-shadow: 2px 2px 0 0 rgba(221, 218, 239, 0.5);
  //   border: 1px solid red;
  opacity: 0.97;
  &-top {
    display: flex;
    // grid-template-columns: repeat(auto-fit, minmax(50rem, 0.5fr));
    width: 100%;

    &-left {
      width: 100%;
      //   border: 1px solid black;
      //   min-width: 100rem;

      &-title {
        font-size: 1.8rem;
        // padding: 2rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        &-dropdown {
          //   padding: 2rem;
          max-width: 20rem;
        }
      }
      &-body {
        padding: 2rem 2rem 2rem 0rem;
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(19rem, 0.25fr));
        // @media only screen and (max-width: $bp-1400) {
        //   grid-template-columns: repeat(auto-fit, minmax(20rem, 0.25fr));
        // }
        // &--item {
        // }
      }
    }
    &-right {
      //   border: 1px solid black;
      width: 30%;

      &-title {
        font-size: 1.8rem;
        font-weight: 600;
      }
      &-body {
        padding: 2rem 2rem 2rem 2rem;
      }
    }
  }
  &-bottom {
    width: 98%;
    &-title {
      font-size: 1.8rem;
      font-weight: 600;
    }
    &-body {
      padding: 3rem 3rem 3rem 0;
    }
  }
}

.stats-grid {
  display: grid;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  width: 100%;

  grid-template-columns: repeat(auto-fill, 20rem);
  grid-template-columns: repeat(auto-fit, 20rem);

  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  @media only screen and (max-width: $bp-1800) {
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  }
  @media only screen and (max-width: $bp-1200) {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  @media only screen and (max-width: $bp-1200) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }

  &--item {
    border-radius: 4px;
    // background-color: white;
    box-shadow: 2px 2px 0 0 rgba(221, 218, 239, 0.5);
    &-1 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 0.5rem solid #c7ca1d;
      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        display: flex;
        // align-items: center;
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
        &-percentage {
          // margin-left: 1rem;
          color: gray;
          color: #9b9b9b;
          font-family: Roboto;
          font-size: 1.4rem;
          font-weight: bold;
          letter-spacing: -0.3px;
          line-height: 2.6rem;
          display: flex;
          &-title {
            padding-right: 1rem;
          }
          &-value {
            color: black;
          }
        }
      }
      &-ratio {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
        display: flex;
        &-title {
          margin-right: 1rem;
        }
        &-value {
          color: black;
        }
      }
    }
    &-2 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 5px solid rgb(5, 72, 255);

      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
      }
      &-percentage {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
      }
    }
    &-3 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 5px solid $color-primary;
      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
      }
      &-percentage {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
      }
    }
    &-4 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 5px solid rgb(255, 41, 41);

      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
      }
      &-percentage {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
      }
    }
    &-5 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 5px solid rgb(255, 145, 0);
      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
      }
      &-percentage {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
      }
    }
    &-6 {
      padding: 1.6rem 2.5rem;
      border: 1px solid #d9d9d9;
      border-bottom: 5px solid rgb(80, 255, 80);

      &-title {
        height: 2.3rem;
        width: 18rem;
        color: #69737d;
        font-family: "IBM Plex Sans";
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.2rem;
      }
      &-count {
        height: 3.4rem;
        width: 18rem;
        color: #2b3037;
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -0.9px;
        line-height: 3.4rem;
      }
      &-percentage {
        height: 2.6rem;
        width: 18rem;
        color: #9b9b9b;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 2.6rem;
      }
    }
  }
}

.apexcharts-xaxistooltip {
  color: white !important;
  background-color: $color-primary !important;
}
.apexcharts-tooltip {
  color: rgba(0, 0, 0, 0.85) !important;
}

// .apexcharts-tooltip .apexcharts-tooltip-series-group.active .apexcharts-markers {
//   background: rgba(10, 5, 31, 0.85) !important;
// }
.apexcharts-legend {
  display: inline-block !important;
  overflow: auto;
  //   padding: 1rem 1rem;
}

.apexcharts-legend-marker {
  border-radius: 0 !important;
  margin-bottom: 1rem;
}
.apexcharts-legend-text {
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.apexcharts-legend-text {
  font-size: 1.6rem !important;
}

.mixed-chart {
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
}

.candidate-tooltip-text {
  color: #c7ca1d;
}
.in-progress-tooltip-text {
  color: #0548ff;
}

.finished-tooltip-text {
  color: #25b7e8;
}

.quit-tooltip-text {
  color: #ff2929;
}

.canceled-tooltip-text {
  color: #ff9100;
}
.hired-tooltip-text {
  color: #50ff50;
}
