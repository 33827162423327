.settings {
  &:not(:last-child) {
    border-bottom: 1px solid rgb(223, 223, 223);
  }
  &-list {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-buttons {
      margin-top: 1.5rem;
      display: flex;
      &-edit {
        padding: 0.2rem 0.6rem;
        margin-right: 1rem;
        background-color: #28a745;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-remove {
        padding: 0.2rem 0.7rem;
        margin-right: 1rem;
        background-color: #ff5050;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-cancel {
        padding: 0.2rem 0.8rem;
        margin-right: 1rem;
        background-color: gray;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-confirm {
        padding: 0.2rem 0.7rem;
        margin-right: 1rem;
        background-color: $color-primary;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &-btns {
      padding-bottom: 1.5rem;
      display: flex;
      &-edit {
        padding: 0.2rem 0.6rem;
        margin-right: 1rem;
        background-color: #28a745;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-remove {
        padding: 0.2rem 0.7rem;
        margin-right: 1rem;
        background-color: #ff5050;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-cancel {
        padding: 0.2rem 0.8rem;
        margin-right: 1rem;
        background-color: gray;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        margin-left: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
      &-confirm {
        padding: 0.2rem 0.7rem;
        margin-right: 1rem;
        background-color: $color-primary;
        color: white;
        border-radius: 3px;
        max-width: 2.7rem;
        max-height: 2.7rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
