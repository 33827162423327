*,
*::after,
*::before {
  margin: 0;
  //   padding: 0;
  box-sizing: inherit;
}

// RESPONSIVE BREAKPOINTS
$bp-1800: 112.5em; // 1800
$bp-1600: 100em; // 1600
$bp-1400: 87.5em; // 1400px
$bp-1200: 75em; // 1200px
$bp-1000: 62.5em; // 1000px
$bp-800: 50em; // 800px;
$bp-600: 37.5em; // 600px;
$bp-400: 25em; // 400px;

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  @media only screen and (max-width: $bp-1200) {
    font-size: 56.25%;
  }
  @media only screen and (max-width: $bp-800) {
    font-size: 50%;
  }
  @media only screen and (max-width: $bp-600) {
    font-size: 38.5%;
  }
  @media only screen and (max-width: $bp-400) {
    font-size: 34%;
  }
}

body {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

html,
body,
#root,
.App {
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}
