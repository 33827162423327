.navigate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 2rem;
  &-left {
    align-items: center;
    font-size: 2rem;
    display: flex;
    font-weight: 500;
    &-btn {
      margin-right: 1rem;
      // padding: 0.5rem 1.5rem;
      // border-radius: 3px;
      background-color: $color-secondary;
      color: white;
      &:hover {
        background-color: $color-secondary-light;
        color: black;
        cursor: pointer;
      }
    }
  }
}
