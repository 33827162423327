.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.9rem 0 2rem 0;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding-left: 4rem;
  padding-right: 4rem;
  z-index: 100;
  &-logo {
    font-size: 2rem;
    font-weight: 600;
  }
  &-links {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    color: gray;

    &--link:not(:last-child) {
      margin-right: 2rem;
    }
    &--link {
      &-img {
        img {
          width: 3.4rem;
          border-radius: 50%;
        }
      }
    }
  }
  margin-bottom: 0.5rem;
}
