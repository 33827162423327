.preview-card {
  background-color: #f3f3f3;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 5px;
  height: 100%;
  cursor: pointer;
  transition: all 0.4s ease-out;
  position: relative;

  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  &-not-clickable {
    cursor: default;
  }
  &-title {
    font-size: 1.2rem;
    min-height: 5rem;
    padding: 1rem 2rem;
    font-weight: 600;
    box-shadow: 2px 2px 0 0 rgba(221, 218, 239, 0.5);
    // min-height: 9rem;

    &-value {
      word-break: break-word;
      letter-spacing: 0.2px;
      line-height: 2.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  &-body {
    display: flex;
    min-height: 5rem;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.2rem;

    &--items {
      &-single {
        display: flex;
        align-items: center;
        clear: both;
        padding: 0.5rem 2rem;
        flex-wrap: wrap;
        color: gray;
        &-title {
          color: black;
          margin-right: 0.5rem;
        }
        &-value {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        &-short {
          padding-right: 10rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: gray;
          &:last-child {
            padding-bottom: 0.5rem;
          }
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            word-break: break-word;
          }
        }
        &-preview {
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 1rem 2rem;
          border: 1px solid rgb(233, 233, 233);
          border-radius: 3px;
          cursor: pointer;
          &:hover > &-value {
            color: $color-primary-light;
          }
          &:hover > &-title {
            color: $color-primary;
          }
          color: gray;
          align-items: center;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
            color: $color-primary;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        &-copy {
          display: flex;
          position: absolute;
          bottom: 1rem;
          left: 2rem;
          cursor: pointer;
          &:hover > &-value {
            color: $color-primary-light;
          }
          &:hover > &-title {
            color: $color-primary;
          }
          color: gray;
          align-items: center;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
            color: $color-primary;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
      ///////////////////////////////////////////////////////////////////////
      &-double {
        // display: flex;
        // align-items: center;
        // clear: both;
        margin-bottom: 0.5rem;
        padding: 0.5rem 2rem;
        &--items {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &-item {
            cursor: pointer;
            &:hover > &-value {
              color: $color-primary-light;
            }
            &:hover > &-title {
              color: $color-primary;
            }
            color: gray;
            display: flex;
            flex-direction: row;

            &-title {
              margin-right: 0.5rem;
              color: black;
            }
            &-value {
              color: $color-primary;
            }
          }
        }
      }
      &-new-line {
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        // min-height: 10rem;
        // display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: gray;
        &-title {
          color: black;
          margin-right: 0.5rem;
        }
        &-value {
          letter-spacing: 0.2px;
          line-height: 2.3rem;
          overflow-x: auto;
          & p > code {
            line-height: 0 !important;
          }
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        &-short {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: gray;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
          }
        }
        &-preview {
          display: flex;
          position: absolute;
          bottom: 1.2rem;
          right: 2rem;
          cursor: pointer;
          &:hover > &-value {
            color: $color-primary-light;
          }
          &:hover > &-title {
            color: $color-primary;
          }
          color: gray;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
            color: $color-primary;
          }
        }
      }
    }
  }
}

.preview-card-modal {
  border-radius: 5px;
  height: 100%;
  transition: all 0.4s ease-out;
  &-title {
    font-size: 1.2rem;
    height: 10%;
    // font-weight: 500;

    &-value {
      word-break: break-word;
      letter-spacing: 0.2px;
      line-height: 2.3rem;
      overflow-x: auto;
      & p > code {
        line-height: 0 !important;
      }
    }
  }
  &-body {
    position: relative;
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.2rem;

    &--items {
      &:first-child {
        padding-top: 1rem;
      }
      &-single {
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: gray;
        &-title {
          color: black;
          margin-right: 0.5rem;
        }
        &-value {
          word-break: break-word;
          letter-spacing: 0.2px;
          line-height: 2.3rem;
        }
        &-short {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: gray;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            word-break: break-word;
            letter-spacing: 0.2px;
            line-height: 2.3rem;
          }
        }
        // &-preview {
        //   display: flex;
        //   position: absolute;
        //   bottom: 1.2rem;
        //   right: 2rem;
        //   cursor: pointer;
        //   &:hover > &-value {
        //     color: $color-primary-light;
        //   }
        //   &:hover > &-title {
        //     color: $color-primary;
        //   }
        //   color: gray;
        //   &-title {
        //     color: black;
        //     margin-right: 0.5rem;
        //   }
        //   &-value {
        //     letter-spacing: 0.2px;
        //     line-height: 2.3rem;
        //     color: $color-primary;
        //   }
        // }
      }
      &-new-line {
        padding-bottom: 1rem;
        // display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: gray;
        &-title {
          color: black;
          margin-right: 0.5rem;
        }
        &-value {
          letter-spacing: 0.2px;
          line-height: 2.3rem;
          overflow-x: auto;
          & p > code {
            line-height: 0 !important;
          }
        }
        &-short {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: gray;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
          }
        }
        &-preview {
          display: flex;
          position: absolute;
          bottom: 1.2rem;
          right: 2rem;
          cursor: pointer;
          &:hover > &-value {
            color: $color-primary-light;
          }
          &:hover > &-title {
            color: $color-primary;
          }
          color: gray;
          &-title {
            color: black;
            margin-right: 0.5rem;
          }
          &-value {
            letter-spacing: 0.2px;
            line-height: 2.3rem;
            color: $color-primary;
          }
        }
      }
    }
  }
}
