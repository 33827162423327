.text-center {
  text-align: center;
}
.login-form {
  max-width: 6rem;
}

.fixed-ratio-div {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  &-project-short {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    color: $color-primary;
    text-shadow: 1px 1px 3px black;
    font-weight: 500;
    @media only screen and (max-width: $bp-600) {
      font-size: 3rem;
    }
    @media only screen and (max-width: $bp-400) {
      font-size: 2rem;
    }
  }
}
.fixed-ratio-div img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  vertical-align: top;
}

.disabled {
  opacity: 0.35 !important;
  cursor: default !important;
  pointer-events: none !important;
  box-shadow: 0 0 0 0 !important;
}
$line-height: 1.7;
$lines-to-show: 4;
.truncate {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 40rem;
  height: $line-height * $lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  // font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-1 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 1.8 * 1; /* Fallback for non-webkit */
  margin: 0 auto;
  // font-size: $font-size;
  line-height: 1.8;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-container {
  max-width: 100rem;
}

.no-content {
  color: gray;
  font-style: italic;
}

.submit-button {
  display: flex;
  justify-content: flex-end;
}

ul {
  padding-inline-start: 20px;
}
.btn {
  &-light {
    background-color: rgb(238, 238, 238);
    &:hover {
      background-color: $color-primary-lightest;
    }
    &-danger {
      color: red !important;
      background-color: rgb(238, 238, 238);
      &:hover {
        background-color: rgb(255, 75, 75);
        color: white !important;
      }
    }
  }
}
.mb-1 {
  margin-bottom: 1.8rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}

.default-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
input[type="radio"] {
  opacity: 0 !important;
}
input[type="checkbox"] {
  opacity: 0 !important;
}
.btn-danger {
  background-color: rgb(255, 80, 80);
}

.text-danger {
  color: red !important;
}
.text-primary {
  color: $color-primary !important;
}
code {
  color: rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
  // word-wrap: none !important;
  white-space: pre;
  // width: 100%;
  // overflow-x: hidden !important;
}
.disabled-light {
  opacity: 0.75 !important;
  cursor: default !important;
  pointer-events: none !important;
  box-shadow: 0 0 0 0 !important;
}

.border-primary {
  border: 1px solid $color-primary !important;
}

.default-container {
  margin-bottom: 2rem;
  opacity: 0.97;
  background-color: #f3f3f3;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 5px;
  font-size: 1.4rem;
}

.default-padding {
  padding: 2rem 3rem;
}

input[type="file"] {
  display: none;
}

.img-upload:hover {
  opacity: 0.7;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.full-width {
  width: 100%;
}

label {
  margin-bottom: 0;
}

.rdw-editor-main {
  overflow: hidden !important;
  height: auto !important;
}
.rdw-editor {
  min-height: 20rem !important;
}

.hyperlink-class {
  text-decoration: underline;
  color: $color-primary;
}
