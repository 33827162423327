.menu {
  height: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
  padding-top: 1rem;
  z-index: 1000;

  &-items {
    padding: 1rem 2rem;
    @media only screen and (max-width: $bp-800) {
      font-size: 1.4rem;
      padding: 0.8rem 1rem;
    }
    @media only screen and (max-width: $bp-600) {
      font-size: 2rem;
      color: $color-primary;
      padding: 1rem 2rem;
    }
    @media only screen and (max-width: $bp-400) {
      padding: 0rem;
      font-size: 1.2rem;
    }
    &--item {
      display: flex;
      align-items: center;
      padding: 1rem 1rem;
      &-image {
        width: 10rem;
        margin: auto;
        margin-bottom: 0.5rem;
        @media only screen and (max-width: $bp-600) {
          width: 5rem;
        }
        @media only screen and (max-width: $bp-400) {
          width: 4rem;
        }
      }
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      @media only screen and (max-width: $bp-600) {
        padding: 2rem 2rem;
        // &:not(:last-child) {
        //   margin-bottom: 4rem;
        // }
      }
      display: flex;
      &-icon {
        color: $color-primary;
      }
      &:hover > &-icon {
        transform: translate(3px, 0px);
        color: black;
      }
      &:hover {
        background-color: rgb(248, 248, 248);
        border-radius: 5px;
        cursor: pointer;
      }
      &-value {
        margin-right: 1rem;
      }
      &-icon2 {
        display: none;
      }
      @media only screen and (max-width: $bp-600) {
        &-icon2 {
          display: inherit;
        }
        &-icon {
          display: none;
        }
        &-value {
          display: none;
        }
      }
      &-sub {
        @media only screen and (max-width: $bp-600) {
          display: none;
        }
        // padding-right: 2rem;
        // padding-left: 2rem;

        &--items {
          &-item {
            &-value {
              border-radius: 5px;
              padding: 0.5rem 1rem;
              color: black;
            }
            &-active {
              &-value {
                background-color: #25b7e8;
                border-radius: 5px;
                padding: 0.5rem 1rem;
                color: white;
              }
            }
          }
        }
      }
    }

    &--item-active {
      display: flex;
      align-items: center;
      background-color: $color-primary;
      border-radius: 5px;

      padding: 1rem 1rem;
      color: white;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      @media only screen and (max-width: $bp-600) {
        padding: 1rem 2rem;
        // &:not(:last-child) {
        //   margin-bottom: 4rem;
        // }
      }
      display: flex;
      &-icon {
        color: white;
      }
      &:hover > &-icon {
        color: black;
      }
      &:hover {
        background-color: $color-primary-light;
        border-radius: 5px;
        color: black;
        cursor: pointer;
      }
      &-value {
        margin-right: 1rem;
      }
      &-icon2 {
        display: none;
      }

      @media only screen and (max-width: $bp-600) {
        &-icon2 {
          display: inherit;
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-left: -1px;
        }
        &-icon {
          display: none;
        }
        &-value {
          display: none;
        }
      }
    }
  }
}
