.candidate-review-row {
  display: flex;
  justify-content: space-between;
  &-left {
    display: flex;
    &--item {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
  &-right {
    margin-left: 1rem;
  }
}
