.confirmation-dialog {
  text-align: center;
  width: 50rem;
  padding: 4rem;
  background: #ffffff;
  box-shadow: 2px 2px 0 0 rgba(221, 218, 239, 0.5);
  color: #000;
  &--title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &--message {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  &--buttons {
    display: flex;
    justify-content: center;
    &--reject {
      width: 15rem;
      padding: 1rem;
      border: 1px solid #fff;
      border-radius: 4px;
      margin: 1rem;
      cursor: pointer;
      font-size: 1.4rem;
      color: #fff;
      background-color: $color-primary;

      &:hover {
        background-color: $color-primary-light;
        cursor: pointer;
      }
    }
    &--confirm {
      width: 15rem;
      padding: 1rem;
      border: 1px solid #fff;
      border-radius: 3px;
      margin: 1rem;
      cursor: pointer;
      color: #fff;
      font-size: 1.4rem;
      background-color: $danger;

      &:hover {
        background-color: $danger-light;
        cursor: pointer;
      }
    }
  }
}

.react-confirm-alert-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(244, 247, 250, 0.9) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
