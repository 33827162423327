// .landing {
//   display: flex;
//   flex-direction: column;
//   height: 91vh;
//   background-image: url("../../img/htec_front.webp");
//   background-size: cover;
//   background-position: top;
//   background-repeat: no-repeat;

//   &-content {
//     display: inline-block;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     min-height: 17.5rem;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     &-logo {
//       height: 2rem;
//       width: 20rem;
//       margin-bottom: 5rem;
//     }
//     &-logo > img {
//       width: 100%;
//     }
//     &-btn {
//       margin-bottom: 4rem;
//     }
//   }
// }
.landing {
  margin-top: 5%;
  padding-left: 35%;
  padding-right: 35%;

  @media only screen and (max-width: $bp-1200) {
    padding-left: 30%;
    padding-right: 30%;
  }
  @media only screen and (max-width: $bp-1000) {
    padding-left: 30%;
    padding-right: 30%;
  }
  @media only screen and (max-width: $bp-800) {
    padding-left: 25%;
    padding-right: 25%;
  }
  @media only screen and (max-width: $bp-600) {
    padding-left: 20%;
    padding-right: 20%;
  }
  @media only screen and (max-width: $bp-400) {
    padding-left: 10%;
    padding-right: 10%;
  }
  &-title {
    font-size: 5rem;
  }
  &-description {
    font-size: 2rem;
    color: #707070;
    padding-bottom: 1rem;
  }
  &-buttons {
    &--btn:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
