.small-btn {
  padding: 0.6rem 0.8rem;
  // background: $color-primary;
  border-radius: 5px !important;
  font-size: 1rem;
  color: white;
  &:hover {
    // background: $color-primary-light;
    color: black;
  }
}
