.exam {
  //   background-color: rgb(255, 255, 255);
  //   border-radius: 3px;
  //   border: 1px solid rgb(211, 211, 211);
  //   opacity: 0.95;
  // padding: 2rem 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  min-height: 25rem;
  background-color: #f3f3f3;
  opacity: 0.97;
  border-radius: 3px;
  transition: all 0.4s ease-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &-title {
    padding: 2rem 3rem;
    font-weight: 500;
    font-size: 3rem;
    border-bottom: 1px solid rgb(211, 211, 211);
  }
  &-body {
    padding: 2rem 3rem;
    background-color: transparent;
  }
  &-body > span {
    background-color: transparent !important;
  }
  &-buttons {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
  }
  &-bottom {
    font-weight: 500;
    padding-bottom: 2rem;
    padding: 2rem 3rem;
    &--item {
      &-title {
        color: black;
        width: 10rem;
      }
      &-info {
        font-size: 1.2rem;
        color: gray;
        font-style: italic;
        padding-bottom: 1rem;
      }
      &-value {
        color: gray;
      }
      &-notifications {
        &-row {
          display: flex;
          flex-direction: column;
          &:not(:last-child) {
            padding-bottom: 1rem;
          }
          &-item {
            display: flex;
            flex-direction: row;
            &-title {
              margin-right: 1rem;
              color: black;
            }
            &-value {
              font-size: 1.4rem;
              color: gray;
              // font-style: italic;
            }
          }
        }
      }
    }
  }
}

.exam-buttons-grid {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 0.25fr));
  align-items: center;
  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-800) {
    grid-template-columns: repeat(auto-fit, minmax(21.5rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-600) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
  @media only screen and (max-width: $bp-400) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
}
.exam-buttons-grid-left {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 0.25fr));
  align-items: center;
  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-800) {
    grid-template-columns: repeat(auto-fit, minmax(21.5rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-600) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
  @media only screen and (max-width: $bp-400) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
}
