.profile-buttons-grid {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 0.33fr));
  align-items: center;
  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-800) {
    grid-template-columns: repeat(auto-fit, minmax(21.5rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-600) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
  @media only screen and (max-width: $bp-400) {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
}
