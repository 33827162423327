.candidate {
  // padding: 2rem 3rem;
  margin-bottom: 2rem;
  background-color: #f3f3f3;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 5px;
  font-size: 1.4rem;
  &-top {
    display: flex;
    @media only screen and (max-width: $bp-600) {
      display: inline;
    }
    justify-content: space-between;
    &-left {
      padding: 2rem 3rem 2rem 3rem;
      width: 30%;
      @media only screen and (max-width: $bp-600) {
        width: 100%;
      }
    }
    &-right {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: 500;
      width: 70%;
      @media only screen and (max-width: $bp-600) {
        width: 100%;
        padding: 2rem 3rem 2rem 3rem;
      }
      &--item {
        display: flex;

        &-title {
          color: black;
          margin-right: 1rem;
          width: 12rem;
        }
        &-value {
          width: 100%;
          color: gray;
          word-break: break-all;
          &:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
  &-bottom {
    padding: 0 3rem;
    font-weight: 500;
    padding-bottom: 2rem;

    &--item {
      padding: 1rem 0rem;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgb(219, 219, 219);
      }
      &-title {
        color: black;
        width: 15rem;
      }
      &-info {
        font-size: 1.2rem;
        color: gray;
        font-style: italic;
        padding-bottom: 1rem;
      }
      &-value {
        word-break: break-word;
        color: gray;
        &-comments {
          &--item {
            display: flex;
            flex-wrap: wrap;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            justify-content: flex-start;
            &-title {
              color: black;
              margin-right: 0.5rem;
            }
            &-value {
              color: gray;
              margin-right: 1rem;
              font-style: italic;
            }
            &-buttons {
              display: flex;
              &--btn {
                &:not(:last-child) {
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: $bp-600) {
    .profile {
      &-top {
        display: inline;
        &-left {
          width: 100%;
        }
        &-right {
          width: 100%;
        }
      }
    }
  }
}
