.btn {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  border: none;
  pointer-events: fill;
  &-primary {
    background-color: $color-primary;
    color: white;
    border-radius: 3px;
    &:hover {
      background-color: $color-primary-light;
      color: black;
    }
  }
  &-primary:focus{
    background-color: $color-primary !important;
  }
  &-primary:active{
    background-color: $color-primary !important;
  }
  &-primary-outline {
    border-radius: 3px;
    &:hover,
    :focus,
    :active {
      border: none;
      outline: none !important;
      background-color: $color-primary;
      color: white;
    }
  }
}

.login-btn {
  background-color: $color-primary !important;
  color: white !important;
  border-radius: 3px !important;
  border: none;
  outline: none !important;
  -webkit-box-shadow: $element-shadow;
  -moz-box-shadow: $element-shadow;
  box-shadow: $element-shadow;

  &:hover {
    // border: none !important;
    // outline: none !important;
    background-color: $color-primary !important;
    color: black !important;
  }
}

.loading-btn{
  min-width: 8rem;
}