.project {
  margin-bottom: 2rem;

  background-color: #f3f3f3;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 5px;
  font-size: 1.4rem;

  display: flex;
  height: 100%;
  // opacity: 0.95;
  //   box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  //   background-color: rgb(255, 255, 255);
  // background-color: #f3f3f3;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 5px;
  //   border: 1px solid rgb(211, 211, 211);
  &-top {
    opacity: 0.97;
    padding: 2rem 3rem;

    border-right: 1px solid rgb(211, 211, 211);

    width: 50%;
    &-header {
      display: flex;
      align-items: center;
      padding-bottom: 2rem;
      &-image {
        width: 10rem;
        margin-right: 2rem;
      }
      &-title {
        // &-right {
          // &--item {
            // max-width: 30rem;
          // }
        // }
        font-size: 2rem;
        font-weight: 500;
        width: 100%;
      }
    }
    &-people {
      font-weight: 500;
    }
  }
  &-bottom {
    width: 100%;
    display: grid;
    grid-gap: 3rem;
    // padding: 2rem 3rem;
    // grid-template-columns: repeat(auto-fit, minmax(20rem, 0.5fr));

    @media only screen and (min-width: $bp-1400) {
      grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    }

    width: 50%;
    font-weight: 500;
    &-technologies {
      padding: 1rem 2rem;
      min-height: 10rem;
      //   background-color: rgb(243, 243, 243);
      border-bottom: 1px solid rgb(211, 211, 211);
      @media only screen and (min-width: $bp-1400) {
        border: 1px solid rgb(233, 233, 233);
      }
    }
    &-seniorities {
      padding: 1rem 2rem;
      min-height: 10rem;
      //   background-color: rgb(243, 243, 243);
      border-bottom: 1px solid rgb(211, 211, 211);
    }
    &-people {
      padding: 1rem 2rem;
      min-height: 10rem;
      //   background-color: rgb(243, 243, 243);
      padding-bottom: 2rem;
    }
    &-teams {
      padding: 1rem 2rem;
      min-height: 10rem;
      //   background-color: rgb(243, 243, 243);
      border-bottom: 1px solid rgb(211, 211, 211);
    }
  }
  @media only screen and (max-width: $bp-1400) {
    display: block;
    border: none;
    &-top {
      background-color: #f3f3f3;
      border: none;
      border-bottom: 1px solid rgb(211, 211, 211);
    }
    &-bottom {
      background-color: #f3f3f3;
      width: 100%;
    }
    &-top {
      width: 100%;
      opacity: 0.95;
    }
  }
}
