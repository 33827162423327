.card {
  min-height: 35rem;
  background-image: linear-gradient(#e6e6e6, #fff);
  opacity: 0.97;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.4s ease-out;

  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  &-image {
    padding: 5rem;
  }
  &-title {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: -5rem;
    text-align: center;
    padding-bottom: 1rem;
    color: $color-primary;
    font-size: 2.3rem;
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
    font-weight: 500;
  }
  &-subtitle {
    text-align: center;
    padding-bottom: 3rem;
    color: #6e6e6e;
    font-size: 1.4rem;
  }
  &-status {
    &-danger {
      text-align: center;
      padding-bottom: 6rem;
      font-size: 1.2rem;
      color: red;
    }
    &-warning {
      text-align: center;
      padding-bottom: 6rem;
      font-size: 1.2rem;
      color: orange;
    }
    &-success {
      text-align: center;
      padding-bottom: 6rem;
      font-size: 1.2rem;
      color: $color-primary;
    }
    &-fail {
      text-align: center;
      padding-bottom: 6rem;
      font-size: 1.2rem;
    }
  }
}
