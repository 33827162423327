.switch-element {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  display: flex;
  @media only screen and (max-width: $bp-600) {
    align-items: center;
    align-content: center;
    align-self: center;
  }

  &--label {
    margin-right: 0.5rem;
  }
}

// .switch-element {
//   display: flex;

//   //   flex-direction: column;
//   &--label {
//     margin-right: 0.5rem;
//     font-size: $font-size-default;
//     padding-bottom: 0.5rem;
//     max-width: 13.5rem;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }
// .switch {
//   position: relative;
//   display: inline-block;
//   width: 5rem;
//   height: 2rem;
// }

// .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }
// // .react-switch-bg {
// //   background: $color-primary !important;
// // }
// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: $inactive-element;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// .slider:before {
//   position: absolute;
//   content: "";
//   height: 1.2rem;
//   width: 1.2rem;
//   left: 0.4rem;
//   bottom: 0.4rem;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// input:focus + .slider {
//   box-shadow: 0 0 1px #2196f3;
// }

// .slider.round {
//   border-radius: 3.4rem;
// }

// .slider.round:before {
//   border-radius: 50%;
// }

// .switch-element-middle {
//   display: flex;
//   justify-content: center;
//   //   flex-direction: column;
//   &--label {
//     margin-right: 0.5rem;
//     font-size: $font-size-default;
//     padding-bottom: 0.5rem;
//     max-width: 13.5rem;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }
// .switch {
//   position: relative;
//   display: inline-block;
//   width: 5rem;
//   height: 2rem;
// }

// .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: $inactive-element;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// .slider:before {
//   position: absolute;
//   content: "";
//   height: 1.2rem;
//   width: 1.2rem;
//   left: 0.4rem;
//   bottom: 0.4rem;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// input:checked + .slider {
//   background-image: linear-gradient(to right, $color-primary, $color-primary-light);
// }

// input:focus + .slider {
//   box-shadow: 0 0 1px #2196f3;
// }

// input:checked + .slider:before {
//   -webkit-transform: translateX(3rem);
//   -ms-transform: translateX(3rem);
//   transform: translateX(3rem);
// }

// .slider.round {
//   border-radius: 3.4rem;
// }

// .slider.round:before {
//   border-radius: 50%;
// }
