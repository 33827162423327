.admin-card {
  height: 100%;
  background-image: linear-gradient(#e6e6e6, #fff);
  opacity: 0.97;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-out;

  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  //   &:hover > &-title {
  //     text-shadow: 1px 3px 5px rgba(150, 150, 150, 1);
  //   }
  //   &-image {
  //     padding: 5rem;
  //   }
  &-title {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    // margin-top: -5rem;
    text-align: center;
    padding-bottom: 1rem;
    color: $color-primary;
    font-size: 2.3rem;
    min-height: 10rem;
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
    font-weight: 500;
  }
  &-subtitle {
    text-align: center;
    padding: 0 1rem 3rem 1rem;
    color: #6e6e6e;
    font-size: 1.4rem;
    min-height: 8rem;
  }
  //   &-status {
  //     text-align: center;
  //     padding-bottom: 6rem;
  //     color: #da1111;
  //     font-size: 1.2rem;
  //   }
}
