.datepicker::selection {
  background: transparent; /* WebKit/Blink Browsers */
  color: #858585 !important;
}
.datepicker::-moz-selection {
  background: transparent; /* Gecko Browsers */
  color: #858585 !important;
}
.react-daypicker-root {
  z-index: 9999;
  display: inline-block;
  position: absolute;
  border: 1px solid #d1d1d1 !important;
  overflow-y: auto !important;
  box-shadow: 1px 1px 1px 1px rgba(78, 59, 188, 0.1) !important;
  top: 6.3rem;
  padding: 1rem;
  background-color: #ffffff;

  & > .header {
    justify-content: space-around !important;
    & > .previous-month {
      cursor: pointer;
    }
    & > .next-month {
      cursor: pointer;
    }
  }
  width: 100% !important;
  & > table {
    width: 100%;
    font-size: 1.2rem;
    @media only screen and (max-width: $bp-600) {
      font-size: 1rem;
    }
    & > tbody > tr > td {
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: $color-primary-light !important;
        color: white;
      }
    }
  }
}
.datepicker {
  font-size: 1.4rem;
  cursor: pointer;
  & > ::selection {
    background: transparent;
  }
}
.today {
  background-color: $color-primary-lightest !important;
  color: #ffffff;
}

.active {
  background-color: $color-primary !important;
  color: #ffffff;
}
input.datepicker {
  color: gray;
}
