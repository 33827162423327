.form-element {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  width: 100%;
  position: relative;
  :last-child {
    margin-bottom: 0px;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8rem;
  &--removeElement {
    position: absolute;
    top: 3.5rem;
    right: 1.5rem;
  }
  &-itemCol {
    position: relative;
    display: flex;
    flex-direction: column;
    // margin-bottom: -2rem;
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
  &-reorder {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    padding-left: 0.5rem;
    color: $color-primary;
    justify-content: center;

    &-up {
      &:hover {
        color: $color-primary-light;
        cursor: pointer;
      }
    }
    &-down {
      &:hover {
        color: $color-primary-light;
        cursor: pointer;
      }
    }
  }

  &--label {
    font-size: $font-size-small;
    text-align: unset;
    color: #000000;
    letter-spacing: 0.17px;
    line-height: 1.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--input {
    height: 3.5rem;
    text-indent: 1rem;
    border-radius: 3px;
    border: $element-border;
    -webkit-box-shadow: $element-shadow;
    -moz-box-shadow: $element-shadow;
    box-shadow: $element-shadow;
    padding-right: 5rem;
    font-size: 1.4rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);

    &:active,
    &:focus {
      outline: none !important;
      border: 1px solid $color-primary;
      &:read-only {
        border: 1px solid #d1d1d1 !important;
      }
    }
  }

  &--dropdown {
    cursor: pointer;
    height: 3.5rem;
    position: relative;
    border-radius: 3px;
    border: $element-border;
    -webkit-box-shadow: $element-shadow;
    -moz-box-shadow: $element-shadow;
    box-shadow: $element-shadow;
    padding-right: 5rem;
    appearance: none;
    padding-left: 1rem;
    &-with-remove {
      &--icon {
        top: 1rem;
        right: 5rem;
        position: absolute;
        font-size: 1.2rem;
      }
      &--remove-element {
        cursor: pointer;
        position: absolute;
        padding: 0.2rem 0.5rem;

        right: 1rem;
        top: 0.9rem;
        font-size: 1.2rem;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        & > img {
          width: 2.5rem;
        }
      }
    }
    & > option:checked {
      background-color: $color-primary-lightest;
    }

    &--icon {
      bottom: 0.7rem;
      right: 1.5rem;
      position: absolute;
      font-size: 1.2rem;
    }
    &:active,
    &:focus {
      outline: none !important;
      border: 1px solid $color-primary;
    }
  }

  &--dropdown-with-remove {
    position: relative;
    cursor: pointer;
    height: 3.8rem;
    border-radius: 3px;
    border: $element-border;
    -webkit-box-shadow: $element-shadow;
    -moz-box-shadow: $element-shadow;
    box-shadow: $element-shadow;
    padding-right: 5rem;
    appearance: none;
    padding-left: 1rem;

    &--icon {
      position: absolute;
      top: 1rem;
      right: 6rem;
      font-size: 1.2rem;
    }
    &--remove-btn {
      background-color: rgb(228, 228, 228);
      cursor: pointer;

      position: absolute;
      padding: 0.34rem 1rem;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 1px solid #d1d1d1;
      right: 1px;
      top: 1px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      & > img {
        margin-top: 0.7rem;
        width: 2.5rem;
      }
    }

    & > option:checked {
      background-color: $color-primary-lightest;
    }

    &:active,
    &:focus {
      outline: none !important;
      border: 1px solid $color-primary;
    }
  }
  &--remove {
    cursor: pointer;
    position: absolute;
    padding: 0.2rem 0.5rem;

    right: 2rem;
    top: 0.4rem;
    font-size: 1.2rem;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &-img {
      width: 2rem;
    }
  }
  &--remove-double {
    cursor: pointer;
    position: absolute;
    padding: 0.2rem 0.5rem;

    right: 1rem;
    top: 0.9rem;
    font-size: 1.2rem;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &-img {
      width: 2.5rem;
    }
  }
  &--textarea {
    resize: none;
    opacity: 0.95;
    height: 13rem;
    width: 100%;
    padding: 1rem;
    padding-top: 1rem;
    border-radius: 3px;
    border: $element-border;
    -webkit-box-shadow: $element-shadow;
    -moz-box-shadow: $element-shadow;
    box-shadow: $element-shadow;

    &:active,
    &:focus {
      outline: none !important;
      border: 1px solid $color-primary;
    }
  }

  &--validation {
    font-size: $font-size-small;
    color: red;
  }
}

.lower-case {
  text-transform: lowercase;
}
