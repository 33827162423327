.demo-wrapper {
  position: relative;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 235);
  background-color: white;
  //   opacity: 0.95;
}
.rdw-colorpicker-modal {
  position: relative;
  z-index: 1000 !important;
  &-options {
    position: relative;
    z-index: 1000 !important;
  }
}

// .demo-editor {
//   background-color: $color-primary !important;
// }
// .demo-toolbar-custom {
//   background-color: $color-primary;
// }
