.uploaded {
  width: 100%;
  &-images {
    align-content: center;
    width: 100%;
    min-height: 10rem;
    display: flex;
    padding: 1rem 2rem;
    flex-wrap: wrap;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.035) !important;
    margin-bottom: 2rem;
    border: 1px solid #d1d1d1;
    border-radius: 3px;

    &-wrapper {
      position: relative;
      background-color: rgb(250, 250, 250);

      &--img {
        height: 8rem;
        border: 1px solid rgb(212, 212, 212);
        img:not(#react-modal-image-img) {
          height: 8rem;
          border: 1px solid rgb(212, 212, 212);
        }
      }
      &--removeMsg {
        display: none;
        position: absolute;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
      }
      &--close {
        height: 5px;
        color: #ff5050;
        font-size: 2.5rem;
        font-weight: 600;
        position: absolute;
        padding: 0;
        top: -13px;
        left: 2px;
        cursor: pointer;
        &:hover {
          color: #bd2130;
        }
      }
    }
    &-button {
      margin-top: 1rem;
      min-height: 5rem;
    }
  }
}
.upload-image-container {
  padding: 2rem 3rem;
  &-img {
    max-width: 80rem;
    margin-bottom: 2rem;
  }
  &-btns {
    display: flex;
    justify-content: space-between;
  }
}
.ReactCrop__image {
  max-height: 50rem;
}
