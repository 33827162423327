.tag {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.15px;
  line-height: 1.6rem;
  padding: 0.1rem 0.6rem;
  display: flex;
  justify-content: space-between;
  max-width: 10rem;
  border-radius: 4px;
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  &--remove {
    margin-left: 0.3rem;
  }
}
.green {
  background-color: #25c025;
}
