.modal-dialog {
  transition: all 0.4s ease-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  width: 100%;
  min-height: 20rem;
  background-color: #f3f3f3;
  opacity: 1 !important;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  @media only screen and (min-width: $bp-1200) {
    max-width: 100rem !important;
    margin: 1.75rem auto !important;
  }
  @media only screen and (max-width: $bp-1200) {
    max-width: 60rem !important;
    margin: 1.75rem auto !important;
  }
  @media only screen and (max-width: $bp-1000) {
    max-width: 50rem !important;
    margin: 1.75rem auto !important;
  }
  @media only screen and (max-width: $bp-800) {
    max-width: 40rem !important;
    margin: 1.75rem auto !important;
  }
}
.modal-content {
  padding: 1rem 2rem;
  cursor: default;
}
.close {
  font-size: 2.5rem;
}
.Modal {
  position: absolute;
  top: 60px !important;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
  padding: 2rem;
  overflow: auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}
