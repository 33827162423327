.SUCCESS_TOAST {
  background-color: $color-primary;
}

.FONT_TOAST {
  font-size: 1.4rem;
  color: #fff;
}

.FAIL_TOAST {
  background-color: rgb(252, 70, 70);
}
