.main {
  grid-area: main;
  min-height: calc(100vh - 14.4rem);
  // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
  padding: 3rem 4rem;
}
.footer {
  grid-area: footer;
}

.grid-menu-container {
  display: grid;
  // grid-template-areas: "menu main main main main main main main";
  grid-template-columns: 20rem 1fr;
  // background-image: url("../../img/htec-front.png");
  background: url("../../img/htec-front.png") no-repeat fixed;
  // background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @media only screen and (max-width: $bp-800) {
    grid-template-columns: 15rem 1fr;
  }
  @media only screen and (max-width: $bp-600) {
    grid-template-columns: 10rem 1fr;
  }
  @media only screen and (max-width: $bp-400) {
    grid-template-columns: 5rem 1fr;
  }
}
.menu-grid {
  grid-row: 1/2;
}
.main-grid {
  grid-row: 2/-1;
  min-height: calc(100vh - 14.5rem);
}
.grid-container {
  display: grid;
  grid-template-areas:
    "main main main main main main main main"
    "main main main main main main main main";
  background-image: url("../../img/htec-front.png");
  background-size: initial;
  background-position: top;
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.padding-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 120rem;
}
