.full-width-btn {
  display: flex;
  //   justify-content: space-between;

  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.5rem;
  color: #fff;
  font-size: 1.4rem;
  transition: all 0.3s;
  text-align: center;
  border: none;
  border-radius: 3px;
  padding: 0 1.5rem;
  background: $color-primary;
  -webkit-box-shadow: $element-shadow;
  -moz-box-shadow: $element-shadow;
  box-shadow: $element-shadow;
  color: rgb(240, 240, 240);

  &:hover {
    background: $color-primary-lightest;
    color: black;
    cursor: pointer;
  }
  &--icon {
    padding-top: 0.7rem;
  }
  &--icon > img {
    width: 2rem;
    height: 2rem;
  }
}
