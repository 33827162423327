.candidates {
  background-image: linear-gradient(#e6e6e6, #fff);
  opacity: 0.97;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease-out;
  font-size: 1.1rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  // padding-bottom: 1.5rem;
  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  padding-bottom: 2rem;
  &-tooltip {
    z-index: 1000;
    &-participant {
      position: absolute;
      font-size: 1.8rem;
      right: 1rem;
      top: 0.5rem;
      color: $color-primary;
    }
    &-confirmation {
      position: absolute;
      font-size: 1.8rem;
      right: 1rem;
      top: 0.5rem;
      // color: #ffad4f;
      color: #ff4141;
    }
    &-review-confirmed {
      color: #25c025;
      font-size: 2rem;
    }
    &-not-reviewed {
      color: #ff4141;
      font-size: 2rem;
    }
    &-review-confirmed2 {
      position: absolute;
      font-size: 1.6rem;
      right: 1rem;
      top: 0.5rem;
      color: #25c025;
      font-size: 2rem;
    }
    &-not-reviewed2 {
      position: absolute;
      font-size: 1.6rem;
      right: 1rem;
      top: 0.5rem;
      color: #ff4141;
      font-size: 2rem;
    }
  }

  &-image {
    padding: 3rem;
    img {
      max-height: 50rem;
      border: 1px solid $color-primary;
      box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 1rem rgba(0, 0, 0, 0.175) !important;
    }
  }
  &-name {
    margin-top: -15px;
    padding-bottom: 1rem;
    text-align: center;
    color: #25b7e8;
    font-size: 1.4rem;
    text-shadow: 1px 1px 1px #969696;
    font-weight: 500;
    word-break: break-word;
  }

  &-item {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;

    &-title {
      color: black;
      margin-right: 0.5rem;
    }
    &-value {
      word-break: break-word;
      color: gray;
    }
  }
}

.exam_participant {
  background-image: linear-gradient(rgb(207, 245, 255), rgb(255, 255, 255));
  // background-image: linear-gradient(rgb(237, 255, 137), rgb(255, 255, 255));
}

.exam_reviewer {
  // background-image: linear-gradient(rgb(255, 227, 196), rgb(255, 255, 255));
  background-image: linear-gradient(#ffd9d9, rgb(255, 255, 255));
  // background-image: linear-gradient($color-primary, rgb(255, 255, 255));
}
