body {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.7;
}
a,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea {
  font-family: "IBM Plex Sans", sans-serif;
}
::-webkit-input-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
}
:-moz-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
}
::-moz-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
}
:-ms-input-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.text-danger {
  color: red;
  font-size: 1.4rem;
}
