.question {
  &-container {
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    background-color: #f3f3f3;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    border-radius: 5px;
    font-size: 1.4rem;
  }
  width: 100%;
  height: 100%;
  //   background-image: linear-gradient(#e6e6e6, #fff);
  background-color: rgb(243, 243, 243);
  opacity: 0.97;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s ease-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  // &-title {
  //   padding: 1rem 2rem;
  //   font-weight: 500;
  //   font-size: 1.3rem;
  //   box-shadow: 2px 2px 0 0 rgba(221, 218, 239, 0.5);

  //   &-value {
  //     letter-spacing: 0.2px;
  //     line-height: 2.3rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 4;
  //     -webkit-box-orient: vertical;
  //   }
  // }
  // &-answer {
  //   padding: 1rem 2rem;
  //   font-size: 1.2rem;
  //   color: gray;
  //   &-title {
  //     color: black;
  //   }
  //   &-value {
  //     letter-spacing: 0.2px;
  //     line-height: 2.3rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 4;
  //     -webkit-box-orient: vertical;
  //   }
  // }

  // &-category {
  //   display: flex;
  //   align-items: center;
  //   padding: 0 2rem 1rem 2rem;
  //   font-size: 1.2rem;
  //   color: gray;
  //   &-title {
  //     color: black;
  //     margin-right: 0.5rem;
  //   }
  //   &-value {
  //     letter-spacing: 0.2px;
  //     line-height: 2.3rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 3;
  //     -webkit-box-orient: vertical;
  //   }
  // }
  // &-difficulty {
  //   display: flex;
  //   align-items: center;
  //   padding: 0 2rem 1rem 2rem;
  //   font-size: 1.2rem;
  //   color: gray;
  //   &-title {
  //     color: black;
  //     margin-right: 0.5rem;
  //   }
  //   &-value {
  //     letter-spacing: 0.2px;
  //     line-height: 2.3rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 3;
  //     -webkit-box-orient: vertical;
  //   }
  // }
  // &-preview {
  //   display: flex;
  //   padding: 0 2rem 1rem 2rem;
  //   font-size: 1.2rem;
  //   &:hover > &-value {
  //     color: $color-primary-light;
  //     cursor: pointer;
  //   }
  //   color: gray;
  //   &-title {
  //     font-size: 1.2rem;
  //     color: black;
  //     margin-right: 0.5rem;
  //   }
  //   &-value {
  //     letter-spacing: 0.2px;
  //     font-size: 1.6rem;
  //     line-height: 2.3rem;
  //     color: $color-primary;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 3;
  //     -webkit-box-orient: vertical;
  //   }
  // }
  // &-reviewed {
  //   display: flex;
  //   align-items: center;
  //   padding: 0 2rem 1rem 2rem;
  //   font-size: 1.2rem;
  //   color: gray;
  //   &-title {
  //     color: black;
  //     margin-right: 0.5rem;
  //     font-size: 1.2rem;
  //   }
  //   &-value {
  //     font-size: 1.2rem;
  //     letter-spacing: 0.2px;
  //     line-height: 2.3rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 3;
  //     -webkit-box-orient: vertical;
  //   }
  // }
  // &-icons {
  //   font-size: 1.4rem;
  //   color: gray;

  //   display: flex;
  //   justify-content: space-between;
  //   &--item {
  //     display: flex;
  //     &-title {
  //       color: black;
  //       margin-right: 5px;
  //     }
  //     &-value {
  //       color: gray;
  //     }
  // }
  // }
}
