.repository {
  position: relative;
  max-width: 30rem;
  border-radius: 3px;
  //   &-flex {
  //     display: flex;
  //   }
  width: 100%;
  &-name {
    display: flex;
    color: rgb(51, 51, 51);
    &-title {
      font-weight: bold;
    }
    &-value {
      margin-left: 1rem;
      color: gray;
    }
  }
  &-updated {
    display: flex;
    color: rgb(51, 51, 51);
    &-title {
      font-weight: bold;
    }
    &-value {
      margin-left: 1rem;
      color: gray;
    }
  }
  &-created {
    display: flex;
    color: rgb(51, 51, 51);
    &-title {
      font-weight: bold;
    }
    &-value {
      margin-left: 1rem;
      color: gray;
    }
  }
  &-link {
    position: absolute;
    top: 1px;
    right: 1px;
  }
}
