.select-dropdown {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  &--icon {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    // width: 5rem;
    // height: 100%;
    font-size: $font-size-default;
    color: $placeholder-color !important;
    z-index: 99;
  }
  overflow: none;
}

.picky {
  min-height: 3.8rem !important;
  border-radius: 3px !important;
  border: $element-border !important;
  font-size: $font-size-default;
  -webkit-box-shadow: $element-shadow !important;
  -moz-box-shadow: $element-shadow !important;
  box-shadow: $element-shadow !important;

  &::placeholder {
    margin-left: 6rem;
  }
  &__placeholder {
    color: $placeholder-color !important;
    font-size: 1.4rem !important;
  }
  &__filter {
    padding: 1rem !important;
  }
  &__input {
    position: relative;
    height: 3.8rem !important;
    padding: 0 !important;
    font-size: 1.4rem;
    border-radius: 3px !important;
    border: none !important;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none !important;
    }
    @media only screen and (max-width: $bp-600) {
      &::after {
        top: 10px !important;
      }
    }
  }
  .picky__filter {
    padding: 1rem 2rem !important;

    &__input {
      height: 3rem !important;
      &:focus {
        outline: none !important;
      }
    }
  }
}
#undefined-list {
  // border: 1px solid $color-primary-lightest;
  border: 1px solid #d1d1d1 !important;
  overflow-y: auto !important;
  box-shadow: 1px 1px 1px 1px rgba(78, 59, 188, 0.1) !important;
  z-index: 9999;
}
.picky__dropdown {
  z-index: 101 !important;
  // max-height: 14rem !important;
  position: unset !important;
  overflow-y: auto !important;
}
.picky__dropdown .option:hover,
.picky__dropdown li:hover {
  background-color: $color-primary-lightest !important;
}

.selected {
  background-color: $color-primary !important;
  color: white;
}

.picky__input {
  padding-left: 3.5rem !important;
  height: 3.5rem !important;
}
