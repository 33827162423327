.users {
  background-image: linear-gradient(#e6e6e6, #fff);
  opacity: 0.97;
  border-radius: 5px;

  cursor: pointer;
  transition: all 0.4s ease-out;
  font-size: 1.1rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  &:hover {
    box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  &-image {
    padding: 3rem;
    img {
      max-height: 50rem;
      border: 1px solid $color-primary;
      box-shadow: 0 0 0 0.3px #2e97ca, 0 1rem 1rem rgba(0, 0, 0, 0.175) !important;
    }
  }
  &-name {
    margin-top: -15px;
    padding-bottom: 1rem;
    text-align: center;
    color: #25b7e8;
    font-size: 1.4rem;
    text-shadow: 1px 1px 1px #969696;
    font-weight: 500;
    word-break: break-word;
  }
  &-item {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    &-title {
      color: black;
      margin-right: 0.5rem;
    }
    &-value {
      color: gray;
    }
    &:last-child {
      padding-bottom: 2rem;
    }
  }
}
