.search-container {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  align-items: center;

  opacity: 0.97;
  border-radius: 5px;
  position: relative;

  grid-template-columns: repeat(auto-fit, minmax(19rem, 0.2fr));
  @media only screen and (max-width: $bp-1400) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.25fr));
  }
  @media only screen and (max-width: $bp-1200) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.5fr));
  }
  @media only screen and (max-width: $bp-1000) {
    display: flex;
    align-items: center;
    display: inline;
    &-search {
      width: 100%;
    }
  }
}
