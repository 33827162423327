.person-container {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175) !important;
  padding: 2rem 3rem;
  background-color: rgb(243, 243, 243);
  opacity: 0.97;
  border-radius: 5px;
  &-top {
    display: flex;
    @media only screen and (max-width: $bp-600) {
      display: inline;
    }
    align-items: center;
    &-image {
      width: 20rem;
      margin-right: 2rem;
      @media only screen and (max-width: $bp-600) {
        width: 100%;
      }
    }
    &-title {
      font-size: 3rem;
      font-weight: 500;
      @media only screen and (max-width: $bp-600) {
        text-align: center;
      }
    }
  }
  &-bottom {
    &--teams {
      margin-top: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgb(211, 211, 211);
      }

      &-title {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: $color-primary;
        // text-align: center;
      }
      &-people {
        &--person {
          align-items: center;
          &-name {
            flex-shrink: 0;

            font-size: 1.6rem;
            font-weight: 500;
            margin-right: 1rem;
          }
          &-seniority {
            font-style: italic;
            margin-right: 1rem;
          }
          //   &-technologies {
          //   }
        }
      }
    }
  }
}
